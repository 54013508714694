import { Consumer } from './consumer';
import { Service } from './service';
import { Vehicle } from './vehicle';

export type ServiceRequest = {
  id: number;
  uuid?: string;
  createdAt: string;
  updatedAt: string;
  date: string;
  expirationReason?: ExpirationReason;
  time: string;
  vehicle: Vehicle;
  service: Service;
  consumer: Consumer;
  status: string;
  packageType: string;
};

export enum Status {
  Requested = 'Requested',
  Pending = 'Pending',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Expired = 'Expired',
}

export enum ExpirationReason {
  InspectionWasNotAligned = 'Inspection Not Aligned', // Requested Status
  InspectionDidNotStart = 'Inspection Did Not Start', // Pending Status
  InspectionDidNotComplete = 'Inspection Did Not Complete', // InProgress Status
}

export type UpdateServiceRequestStatus = {
  id: number;
  status: string;
};

export type UpdateServiceRequestDto = {
  serviceRequestId: number;
  date: string;
  time: string;
};
